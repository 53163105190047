// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-noticias-js": () => import("./../../../src/components/noticias.js" /* webpackChunkName: "component---src-components-noticias-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admision-js": () => import("./../../../src/pages/admision.js" /* webpackChunkName: "component---src-pages-admision-js" */),
  "component---src-pages-colegio-js": () => import("./../../../src/pages/colegio.js" /* webpackChunkName: "component---src-pages-colegio-js" */),
  "component---src-pages-comunidad-atencion-apoderado-js": () => import("./../../../src/pages/comunidad/atencion-apoderado.js" /* webpackChunkName: "component---src-pages-comunidad-atencion-apoderado-js" */),
  "component---src-pages-comunidad-centro-alumnos-js": () => import("./../../../src/pages/comunidad/centro-alumnos.js" /* webpackChunkName: "component---src-pages-comunidad-centro-alumnos-js" */),
  "component---src-pages-comunidad-centro-padres-js": () => import("./../../../src/pages/comunidad/centro-padres.js" /* webpackChunkName: "component---src-pages-comunidad-centro-padres-js" */),
  "component---src-pages-comunidad-horario-clases-js": () => import("./../../../src/pages/comunidad/horario-clases.js" /* webpackChunkName: "component---src-pages-comunidad-horario-clases-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-noticias-js": () => import("./../../../src/pages/noticias.js" /* webpackChunkName: "component---src-pages-noticias-js" */),
  "component---src-pages-retorno-seguro-js": () => import("./../../../src/pages/retorno-seguro.js" /* webpackChunkName: "component---src-pages-retorno-seguro-js" */)
}

